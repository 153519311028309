import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import L, { map } from "leaflet";
import { Marker, useMap, useMapEvents } from "react-leaflet";
import { navigate } from "gatsby";
import styled from "styled-components";
import { promiseToFlyTo, getCurrentLocation } from "lib/map";

import Layout from "components/Layout";
import Map from "components/Map";
import EventList from "components/EventList";

import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { DateTime } from "luxon";
import { StaticImage } from "gatsby-plugin-image";
import EventDetails from "../components/EventDetails";
// const iconPerson = new L.Icon({
//   iconUrl: icon,
//   iconRetinaUrl: icon,
//   iconAnchor: null,
//   popupAnchor: [0, -25],
//   shadowUrl: null,
//   shadowAnchor: null,
//   iconSize: new L.Point(30, 50),
//   className: "leaflet-icon",
// });

const LOCATION = {
  lat: process.env.GATSBY_GPS_LAT,
  lng: process.env.GATSBY_GPS_LNG,
};

const CENTER = [LOCATION.lat, LOCATION.lng];
const DEFAULT_ZOOM = 10;
const ZOOM = 13;

const timeToZoom = 5000;
const timeToOpenPopupAfterZoom = 4000;
const timeToUpdatePopupAfterZoom = timeToOpenPopupAfterZoom + 3000;

const SCEventDetails = styled.div`
  padding: 20px;
  text-align: center;
  color: white;
  background-color: #008264;
  height: 100%;
`;

const SCMapHolder = styled.div`
  &.map-shrink {
    flex: 1;
  }

  flex: 4;
  min-height: 40%;
`;

const SCControls = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    height: 100%;
    min-width: 60px;
  }

  select {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.5rem;
  }
`;

const SCListHolder = styled.div`
  flex: 6;
  overflow-y: auto;
`;
/**
 * MapEffect
 * @description This is an example of creating an effect used to zoom in and set a popup on load
 */

const MapEffect = ({ markerRef }) => {
  const map = useMap();
  useEffect(() => {
    if (!markerRef.current || !map) return;

    (async function run() {
      const popup = L.popup({
        maxWidth: 800,
      });

      const location = await getCurrentLocation().catch(() => LOCATION);

      const { current: marker } = markerRef || {};

      marker.setLatLng(location);
      popup.setLatLng(location);

      setTimeout(async () => {
        await promiseToFlyTo(map, {
          zoom: ZOOM,
          center: location,
        });

        marker.bindPopup(popup);

        setTimeout(() => marker.openPopup(), timeToOpenPopupAfterZoom);
        // setTimeout(
        //   () => marker.setPopupContent(popupContentGatsby),
        //   timeToUpdatePopupAfterZoom
        // );
      }, timeToZoom);
    })();
  }, [map, markerRef]);

  return null;
};

MapEffect.propTypes = {
  markerRef: PropTypes.object,
};

function LocationMarker({ eventItem, position, mapR }) {
  const markerClicked = (marker, index) => {
    console.log(marker.position.lat, marker.position.lng);
  };

  // const map = useMapEvents({
  //   click(e) {
  //     //map.locate();
  //     map.flyTo(e.latlng, map.getZoom() + 3);
  //   },
  //   locationfound(e) {},
  // });

  return (
    <Marker
      position={position}
      eventHandlers={{
        click: (event) => {
          mapR.current.flyTo(event.latlng, 3);
        },
      }}
    ></Marker>
  );
}

const EventsPage = ({ pageContext }) => {
  const events = pageContext.events;
  const mapRef = useRef();

  const [activeEvent, setActiveEvent] = useState(null);

  const mapSettings = {
    center: CENTER,
    defaultBaseMap: "OpenStreetMap",
    zoom: DEFAULT_ZOOM,
  };

  useEffect(() => {
    if (activeEvent) {
      // FlyTo the active event
      //  mapRef.current.flyTo([32, 11], 13);
    }
  }, [activeEvent, mapRef]);

  const [lmap, setlmap] = useState(null);

  return (
    <Layout pageName="venues">
      <Helmet>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <title>Events</title>
      </Helmet>
      <SCMapHolder ref={mapRef}>
        <Map {...mapSettings} whenCreated={(map) => setlmap(map)}>
          {events.map((node, idx) => (
            <Marker
              key={`marker-${idx}`}
              position={[node.node.venue.latitude, node.node.venue.longitude]}
              eventHandlers={{
                click: (event) => {
                  console.log(lmap);
                  //lmap.flyTo([23, 32], 3);

                  setActiveEvent(node.node);
                },
              }}
            ></Marker>
          ))}
        </Map>
      </SCMapHolder>
      <SCControls>
        {!activeEvent && (
          <select
            value={pageContext.date}
            onChange={(e) => navigate(`/events/${e.target.value}`)}
          >
            {Array.from(Array(30).keys()).map((i) => {
              // show current date plus i
              const date = new Date();
              date.setDate(date.getDate() + i);
              const dateDT = DateTime.fromJSDate(date);
              return (
                <option
                  key={i}
                  value={dateDT.toFormat("cccc-LLLL-d-yyyy").toLowerCase()}
                >
                  {dateDT.toFormat("cccc, MMMM d")}
                </option>
              );
            })}
          </select>
        )}
        {activeEvent && (
          <button onClick={() => setActiveEvent(null)}>Close</button>
        )}
      </SCControls>
      {activeEvent && (
        <SCEventDetails>
          <EventDetails event={activeEvent}></EventDetails>
        </SCEventDetails>
      )}
      {!activeEvent && (
        <SCListHolder>
          <h1>Events</h1>

          <EventList
            setActiveEvent={setActiveEvent}
            events={events}
            displayType={"event"}
          ></EventList>
        </SCListHolder>
      )}
    </Layout>
  );
};

export default EventsPage;

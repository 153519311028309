import React from "react";
import { DateTime } from "luxon";

export default function EventDetails({ event }) {
  return (
    <div>
      <h1>{event.artist.artistName}</h1>
      <h2>{event.venue.venueName}</h2>

      <h2>
        {DateTime.fromISO(event.startTime, { zone: "utc" })
          .setZone(process.env.GATSBY_TIME_ZONE)
          .toFormat("cccc h:mm a")}
        -{" "}
        {DateTime.fromISO(event.endTime, { zone: "utc" })
          .setZone(process.env.GATSBY_TIME_ZONE)
          .toFormat("h:mm a")}
      </h2>
      <h3>
        {event.venue.address}, {event.venue.state} {event.venue.zipCode}
      </h3>
      <h3>{event.venue.phoneNumber}</h3>
    </div>
  );
}

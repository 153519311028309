import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { DateTime } from "luxon";

export default function EventList({ setActiveEvent, events, displayType }) {
  // sort array in ascending order by start time
  const eventsSortedByDay = events.sort((a, b) => {
    return (
      DateTime.fromISO(a.startTime, { zone: "utc" }).setZone(
        process.env.GATSBY_TIME_ZONE
      ).weekday -
      DateTime.fromISO(b.startTime, { zone: "utc" }).setZone(
        process.env.GATSBY_TIME_ZONE
      ).weekday
    );
  });

  return (
    <ListGroup>
      {eventsSortedByDay.map((node, idx) => (
        <ListGroupItem
          onClick={() => setActiveEvent(node.node)}
          key={`LIST-${idx}`}
        >
          {displayType === "artist" && (
            <>
              {node.node.venue.venueName} -{" "}
              {DateTime.fromISO(node.node.startTime, { zone: "utc" })
                .setZone(process.env.GATSBY_TIME_ZONE)
                .toFormat("cccc @ h:mm a")}
            </>
          )}
          {displayType === "event" && (
            <>
              {node.node.artist.artistName} @ {node.node.venue.venueName} -{" "}
              {DateTime.fromISO(node.node.startTime, { zone: "utc" })
                .setZone(process.env.GATSBY_TIME_ZONE)
                .toFormat("h:mm a")}
            </>
          )}
          {displayType === "venue" && (
            <>
              {node.node.artist.artistName} -{" Every "}
              {DateTime.fromISO(node.node.startTime, { zone: "utc" })
                .setZone(process.env.GATSBY_TIME_ZONE)
                .toFormat("cccc h:mm a")}
            </>
          )}
        </ListGroupItem>
      ))}
    </ListGroup>
  );
}
